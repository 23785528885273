<template>
  <div class="c-order-filters">
    <div class="card">
      <div class="card-header">
        <h3 class="mb-0">Filtrowanie</h3>
      </div>
      <div class="card-body">
        <form
          @submit.prevent="handleSubmit"
          @reset.prevent="handleReset"
        >
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <base-input label="Numer zamówienia" v-model="numberPrivate" />
            </div>
            <div class="col-md-6 col-lg-3">
              <base-input label="Imię" v-model="firstNamePrivate" />
            </div>
            <div class="col-md-6 col-lg-3">
              <base-input label="Nazwisko" v-model="lastNamePrivate" />
            </div>
            <div class="col-md-6 col-lg-3">
              <base-input label="Telefon" v-model="phonePrivate" />
            </div>
            <div class="col-md-6 col-lg-3">
              <base-input label="Email" v-model="emailPrivate" />
            </div>
<!--            <div class="col-md-6 col-lg-3">-->
<!--              <base-input label="Data zamówienia">-->
<!--                <datepicker v-model="cDate" type="date" />-->
<!--              </base-input>-->
<!--            </div>-->
            <div class="col-md-6 col-lg-3">
              <base-input label="Status">
                <el-select v-model="statusPrivate"
                           filterable
                           clearable
                           placeholder="Wybierz status zamówienia"
                >
                  <el-option v-for="option in options"
                             :key="option.id"
                             :label="option.name"
                             :value="option.id">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="text-right">
            <button class="btn btn-outline-primary" type="reset">
              Wyczyść filtry
            </button>
            <button class="btn btn-primary" type="submit">
              Filtruj zamówienia
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
const props = [ 'number', 'firstName', 'lastName', 'status', 'email', 'phone' ]

import { Select, Option } from 'element-ui'
// import Datepicker from "@/components/Datepicker";

export default {
  name: "OrdersFilter",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    ...props.reduce((acc, prop) => ({
      ...acc,
      [prop]: {
        type: String,
        default: () => ''
      }
    }), {})
  },
  data() {
    return {
      ...props.reduce((acc, key) => ({
        ...acc,
        [`${key}Private`]: this[key] ?? ''
      }), {}),
      options: [
        {
          id: 'pending',
          name: 'Oczekujące'
        },
        {
          id: 'paid',
          name: 'Zapłacone'
        },
        {
          id: 'in_progress',
          name: 'W trakcie realizacji'
        },
        {
          id: 'processing',
          name: 'Przetwarzane'
        },
        {
          id: 'shipped',
          name: 'Wysłane'
        },
        {
          id: 'delivered',
          name: 'Dostarczone'
        },
        {
          id: 'completed',
          name: 'Zakończone'
        },
        {
          id: 'cancelled',
          name: 'Anulowane'
        },
        {
          id: 'rejected',
          name: 'Odrzucone'
        }
      ]
    }
  },
  beforeMount() {
    props.forEach( key => {
      this[`${key}Private`] = this[key]
    })
  },
  watch: {
    ...props.reduce((acc, key) => ({
      ...acc,
      [key](value) {
        this[`${key}Private`] = value
      }
    }), {})
  },
  computed: {
    propsToExport() {
      const dateFormatFromPropertyFunction = prop => {
        try {
          return this[`${prop}Private`].format('YYYY-MM-DD')
        } catch (e) {
          return ''
        }
      }
      const handlePropertyTrim = prop => {
        try {
          const val = this[`${prop}Private`]
          return val instanceof Date ? val.toLocaleDateString('pl-PL').split('.').join('-') : val.trim()
        } catch (e) {
          console.log(prop)
          return ''
        }
      }
      const object = props.reduce((acc, key) => ({
        ...acc,
        [key]: handlePropertyTrim(key)
      }), {})

      return Object.fromEntries(Object.entries(object).filter( ([key, value]) => value))
    },
    cDate: {
      get() {
        return this.datePrivate
      },
      set(value) {
        this.datePrivate = value
        this.emitQueryEvent()
      }
    }
  },
  methods: {
    handleSubmit(){
      return this.emitQueryEvent()
    },
    handleReset() {
      props.forEach( key => {
        this[`${key}Private`] = ''
      })
      return this.emitQueryEvent()
    },
    emitQueryEvent() {
      debugger;
      this.$emit('query-updated', this.propsToExport)
    }
  }

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
