<template>
  <div>
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>

    <div class="container-fluid mt--6">
      <orders-filter
        :number="query.number"
        :phone="query.phone"
        :email="query.email"
        :first-name="query.firstName"
        :last-name="query.lastName"
        :status="query.status"
        @query-updated="handleQueryUpdate"
      />
      <table-navigation :table-title="tableTitle"
                        :table-buttons="tableButtons"
                        :table-columns="tableColumns"
                        :table-data="tableData"
                        :router-view="routerView"
                        :connection-error="connectionError"
                        :pagination="pagination"
                        @page="changePage"
                        @filters="changeFilters"
      >
      </table-navigation>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
import TableNavigation from "@/views/Store/Tables/TableNavigation";
import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
import OrdersFilter from "@/components/Orders/OrdersFilter.vue";

const {mapState, mapActions} = createNamespacedHelpers('OrderListModule')

export default {
  name: 'OrderList',
  components: {
    OrdersFilter,
    BreadcrumbHeader,
    TableNavigation,
  },
  data() {
    return {
      tableTitle: 'Lista zamówień',
      routerView: 'order-view',
      tableButtons: [],
      tableColumns: [
        {
          prop: 'number',
          label: 'Numer',
          minWidth: 60,
          sortable: true,
          link: true,
        },
        {
          prop: 'invoiceNumber',
          label: 'Faktura',
          minWidth: 80,
          sortable: false,
          link: true,
        },
        {
          prop: 'customer',
          label: 'Klient',
          minWidth: 90,
          sortable: true,
        },
        {
          prop: 'date',
          label: 'Data',
          minWidth: 80,
          sortable: true,
        },
        {
          prop: 'products',
          label: 'Cena produktów',
          minWidth: 60,
          sortable: false,
        },
        {
          prop: 'total',
          label: 'Kwota',
          minWidth: 60,
          sortable: false,
        },
        {
          prop: 'status',
          label: 'Status',
          minWidth: 60,
          sortable: false,
        },
        {
          prop: 'shipping',
          label: 'Metoda płatności',
          minWidth: 60,
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState({
      tableData: state => state.items,
      totalItems: state => state.totalItems,
      tableFilters: state => state.filterValues,
      connectionError: state => state.apiConnectionErr
    }),
    breadcrumb() {
      return [
        {title: 'Zamówienia', link: {name: 'orders'}},
        {title: 'Detaliczne'},
      ];
    },
    handleError() {
      return `Błąd ${this.connectionError.status} - ${this.$t[this.connectionError.status]}`;
    },
    query() {
      const { number, firstName, lastName, status, phone, email } = this.$route.query
      return {
        number: number || '',
        firstName: firstName || '',
        orderNumber: number || '',
        lastName: lastName || '',
        phone: phone || '',
        email: email || '',
        status: status || ''
      }
    },
    pagination() {
      return {
        totalItems: this.totalItems,
        perPage: 20,
        currentPage: parseInt(this.$route.params.pageNum || 1),
      };
    },
  },
  methods: {
    changePage(page) {
      this.$router.push({name: 'order-list', params: {pageNum: page}, query: { ...this.$route.query }});
    },
    changeFilters(filters) {
      this.$store.commit('OrderListModule/setFilterValues', filters);
      this.$store.dispatch('OrderListModule/fetchList', {
        token: this.$store.state.Auth.token,
        page: 1,
      });
    },
    handleQueryUpdate(query) {
      this.$router.push({ name: 'order-list', query })
    },
    parseQuery(to) {
      const firstName = to.query.firstName ? { 'searchedFirstName' : to.query.firstName } : {}
      const lastName = to.query.lastName ? { 'searchedLastName' : to.query.lastName } : {}
      const number = to.query.number ? { 'number' : to.query.number } : {}
      const email = to.query.email ? { 'searchedEmail' : to.query.email } : {}
      const phone = to.query.phone ? { 'searchedPhone' : to.query.phone } : {}
      const status = to.query.status ? { 'status' : to.query.status } : {}
      // const date = to.query.date ? { 'createdAt' : '2023-02-12T12:00:00' } : {}

      return {
        ...firstName,
        ...lastName,
        ...number,
        ...email,
        ...phone,
        ...status,
        // ...date
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('OrderListModule/resetItems', []);
    return next()
  },
  beforeRouteUpdate(to, from, next) {

    this.$store.commit('OrderListModule/resetItems', []);
    this.$store.dispatch('OrderListModule/fetchList', {
      token: this.$store.state.Auth.token,
      page: to.params.pageNum || 1,
      query: this.parseQuery(to)
    });
    return next()
  },
  beforeMount() {
    this.$store.dispatch('OrderListModule/fetchList', {
      token: this.$store.state.Auth.token,
      page: this.$route.params.pageNum || 1,
      query: this.parseQuery(this.$route)
    });
  }
};
</script>
